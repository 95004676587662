<template>
	<div>
		<!-- 内容 -->
		<div class="orderdetailsbox">
			<div class="orderback">
				<a href="javascript:void(0);"  @click="headback()"><i class="el-icon-arrow-left"></i> 返回</a>
				<span>/</span>
				<h5>发票详情</h5>
			</div>
			<div class="orderdetailsup ">
				<ul class="clearfloat">
					<li class="active">
						<h5><span>1</span></h5>
						<p>申请开票</p>
						<strong>{{invoucedcdetail.created_at}}</strong>
					</li>
					<li :class="invoucedcdetail.status == 3 ? 'active' : ''">
						<h5><span>2</span></h5>
						<p>已开票</p>
						<strong>{{ invoucedcdetail.invoicing_time == 0 ? '' : invoucedcdetail.invoicing_time }}</strong>
					</li>
					<li :class="invoucedcdetail.is_send == 2 ? 'active' : ''">
						<h5><span>3</span></h5>
						<p>已邮寄</p>
						<strong>{{ invoucedcdetail.send_time  == 0 ? '' : invoucedcdetail.send_time}}</strong>
					</li>
					<li :class="invoucedcdetail.status == 2 ? 'active' : ''">
						<h5><span>4</span></h5>
						<p>已完成</p>
						<strong>{{ invoucedcdetail.send_time  == 0 ? '' : invoucedcdetail.send_time}}</strong>
					</li>
				</ul>
			</div>
			<div class="orderdetailscon">
				<ul class="clearfloat d-flex">
					<li>
						<div class="orderdetailstitle clearfloat">
							<h5>发票信息</h5>
							<!-- <a href="javascript:void(0);"  @click="upinvoicetype = true">上传发票</a> -->
						</div>
						<div class="orderdetailsbut">
							<p class="clearfloat">
								<span style="line-height: 38px;">发票号</span>
								<strong class="clearfloat addorderbut"><input type="text" v-model="invoucedcdetail.invoice_no"> <button v-if="hasPerm(['order.invoice.code'])" @click="xiugai(invoucedcdetail.invoice_no,invoucedcdetail.id)">确认修改</button></strong>
							</p>
							<p class="clearfloat">
								<span>申请开票金额</span>
								<strong>{{invoucedcdetail.order?.total_price}}</strong>
							</p>
							<p class="clearfloat">
								<span>订单单号</span>
								<strong>{{invoucedcdetail.order_no}}</strong>
							</p>
							<p class="clearfloat" v-if="invoucedcdetail.invoicing_time > 0">
								<span>开票时间</span>
								<strong>{{invoucedcdetail.invoicing_time}}</strong>
							</p>
							<p class="clearfloat">
								<span>发票类型</span>
								<strong>{{invoucedcdetail.content_type == 1 ? '明细' : '大类'}}</strong>
							</p>
							<p class="clearfloat" v-if="invoucedcdetail.consignee_name">
								<span>收票人姓名</span>
								<strong>{{invoucedcdetail.consignee_name}}</strong>
							</p>
							<p class="clearfloat" v-if="invoucedcdetail.consignee_mobile">
								<span>收票人电话</span>
								<strong>{{invoucedcdetail.consignee_mobile}}</strong>
							</p>
							<p class="clearfloat" v-if="invoucedcdetail.consignee_address">
								<span>收票人地址</span>
								<strong>{{invoucedcdetail.consignee_address}}</strong>
							</p>
							<!-- <p class="clearfloat">
								<span>发票备注</span>
								<strong>暂无</strong>
							</p> -->
						</div>
					</li>
					<li>
						<!-- 暂时隐藏误删 -->
						<div class="orderdetailstitle clearfloat" >
							<h5>发票邮寄信息</h5>
							<div class="orderdetailsright clearfloat" style="display: none;">
								<el-tooltip class="boxicon" effect="light" placement="left">
									<div slot="content" class="wuliulist">
										<div class="wuliutop">
											<p><i><img src="../../assets/images/sf.png" alt=""></i> 顺丰快递 <span>78648258752905</span> <strong @click="copy('https://demo.oeob.net/#/')">复制</strong></p>
										</div>
										<div class="wuliubut">
											<ul>
												<li class="clearfloat active">
													<i></i>
													<div class="wuliutext">
														<h5>你的订单已经安排车辆</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
												<li class="clearfloat">
													<i></i>
													<div class="wuliutext">
														<h5>您的订单已经进入京东南昌仓库准备出库</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
												<li class="clearfloat">
													<i></i>
													<div class="wuliutext">
														<h5>拣货完成</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
												<li class="clearfloat">
													<i></i>
													<div class="wuliutext">
														<h5>温馨提示：您的订单预计3月18日送达</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
												<li class="clearfloat">
													<i></i>
													<div class="wuliutext">
														<h5>您提交了订单，请等待系统确认</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
												<li class="clearfloat">
													<i></i>
													<div class="wuliutext">
														<h5>拣货完成</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
												<li class="clearfloat">
													<i></i>
													<div class="wuliutext">
														<h5>温馨提示：您的订单预计3月18日送达</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
												<li class="clearfloat">
													<i></i>
													<div class="wuliutext">
														<h5>您提交了订单，请等待系统确认</h5>
														<p>2023-03-26 11:23:45</p>
													</div>
												</li>
											</ul>
										</div>
									</div>
									<a href="javascript:void(0);">物流信息</a>
								</el-tooltip>
							</div>
						</div>
						<div class="orderdetailsbut">
							<p class="clearfloat">
								<span>收件人</span>
								<strong>{{invoucedcdetail.order?.receiver_name}}</strong>
							</p>
							<p class="clearfloat">
								<span>手机号</span>
								<strong>{{invoucedcdetail.order?.receiver_mobile}}</strong>
							</p>
							<p class="clearfloat">
								<span>收件地址</span>
								<strong>{{invoucedcdetail.order?.address}}</strong>
							</p>
							<p class="clearfloat">
								<span>配送方式</span>
								<strong>{{invoucedcdetail.express}}</strong>
							</p>
							<p class="clearfloat">
								<span>物流单号</span>
								<strong>{{invoucedcdetail.express_no}}</strong>
							</p>
						</div>
					</li>
					<li>
						<div class="orderdetailstitle clearfloat">
							<h5>发票抬头</h5>
							<a href="javascript:void(0);"  @click="upinvoicetype = true">查看发票</a>
						</div>
						<div class="orderdetailsbut">
							<p class="clearfloat">
								<span>抬头类型</span>
								<strong>{{invoucedcdetail.head_type == 1 ? '个人' : '企业'}}</strong>
							</p>
							<p class="clearfloat">
								<span>发票类型</span>
								<strong>{{invoucedcdetail.invoice_type == 1 ? '增值税普通票' : '增值税专用票'}}</strong>
							</p>
							<p class="clearfloat">
								<span>发票抬头</span>
								<strong>{{invoucedcdetail.header}}</strong>
							</p>
							<p class="clearfloat">
								<span>纳税人识别码</span>
								<strong>{{invoucedcdetail.invoice_content?.taxpayer}}</strong>
							</p>
							<p class="clearfloat">
								<span>开户行</span>
								<strong>{{invoucedcdetail.invoice_content?.bank_num}}</strong>
							</p>
							<p class="clearfloat">
								<span>银行账户</span>
								<strong>{{invoucedcdetail.invoice_content?.bank_account}}</strong>
							</p>
							<p class="clearfloat">
								<span>注册电话</span>
								<strong>{{invoucedcdetail.invoice_content?.register_tel}}</strong>
							</p>
							<p class="clearfloat">
								<span>注册地址</span>
								<strong>{{invoucedcdetail.invoice_content?.register_address}}</strong>
							</p>
						</div>
					</li>
				</ul>
			</div>
			<div class="orderdetailsbuton" v-if="invoucedcdetail.goods && invoucedcdetail.goods.length">
				<div class="orderdetailstitle clearfloat">
					<h5>商品信息</h5>
					<!-- <a href="javascript:void(0);">导出商品信息</a> -->
				</div>
				<div class="orderdetailtable">
					<div class="orderdetailinvtitle clearfloat">
						<p>商品</p>
						<p>单价</p>
						<p>购买数量</p>
						<p>税率</p>
						<p>单位</p>
						<p>小计</p>
					</div>
					<div class="orderdetailtablecon">
						<div class="orderdetailtablelist addinlist d-flex align-items-center" v-for="(item,index) in invoucedcdetail.goods" :key="index">
							<div class="orderdetail-item">
								<div class="order_itemlist d-flex align-items-center">
									<div class="order_itemimg">
										<img :src="item.cover_pic" alt="">
									</div>
									<div class="order_itemtext">
										<a href="javascript:void(0);">{{item.goods_name}}</a>
									</div>
								</div>
							</div>
							<div class="orderdetail-item">
								<div class="orderdetail-itemtext">
									<p>￥{{item.price}}</p>
								</div>
							</div>
							<div class="orderdetail-item">
								<div class="orderdetail-itemtext">
									<p>{{item.num}}</p>
								</div>
							</div>
							<div class="orderdetail-item">
								<div class="orderdetail-itemtext">
									<p>{{item.tax_rate}}%</p>
								</div>
							</div>
							<div class="orderdetail-item">
								<div class="orderdetail-itemtext">
									<p>件</p>
								</div>
							</div>
							<div class="orderdetail-item">
								<div class="orderdetail-itemtext">
									<p>￥{{item.total_price}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="orderdetailast clearfloat">
					<div class="orderdetailastright">
						<p><b>开票总金额</b> <strong>{{invoucedcdetail.order?.total_price}}元</strong></p>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 上传发票 -->
		<el-dialog title="上传发票" class="upinvoicetc" :visible.sync="upinvoicetype">
			<div class="upinvoicebox">
				<div class="deliveryboxbut">
					<ul class="clearfloat">
						<li class="clearfloat">
							<span class="deliverytexted">选择发票：</span>
							<div class="deliveryright">
								<template>
								  <el-radio v-model="invoiceradio" v-for="(item,index) in common_info['invoice.bill.type.list']" :key="index" :label="item.key">{{item.value}}</el-radio>
								</template>
							</div>
						</li>
						<li class="clearfloat"  v-if="invoiceradio == 0">
							<span class="deliverytexted">纸质发票：</span>
							<div class="clearfloat">
								<div class="deliveimgs clearfloat" v-if="invoiceimglist && invoiceimglist.length">
									<p v-for="(item,index) in invoiceimglist" :key="index">
										<img :src="item.file_url" alt="">
										<span @click="delectimg(index)">删除</span>
									</p>
								</div>
								<div class="upinvoiceimg" @click="photoVisible(1)" v-if="hasPerm(['order.invoice.upload'])"><i class="el-icon-plus"></i></div>
							</div>
						</li>
						<li class="clearfloat" v-else>
							<span class="deliverytexted">电子发票：</span>
							<div class="upinvoiceinput clearfloat">
								<div class="clearfloat">
									<input type="text" v-model="elecontext" placeholder="请填写电子票地址 例:https://www.xxx.cn">
									<button @click="addelectron()" v-if="hasPerm(['order.invoice.upload'])">添加电子票</button>
								</div>
								<div class="upinvlist clearfloat" v-if="electronlist && electronlist.length">
									<div class="clearfloat upinvlistcon" v-for="(item,index) in electronlist" :key="index">
										<input type="text" :value="item.file_url" disabled>
										<strong class="el-icon-delete" @click="delectelectron(index)"></strong>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="upinvoicetype = false">取 消</el-button>
				<el-button @click="invoiceupimg()" type="primary">确 定</el-button>
			</div>
		</el-dialog>
		
		<!-- 图片选择器 -->
		<pictureSelect ref="imglist" :multipled="is_multiple"  @change="imgbrand($event)"></pictureSelect>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				is_multiple:0,
				invoiceimglist: [],
				id: this.$route.query.id,
				upinvoicetype: false,//控制上传发票弹层是否显示
				invoiceradio: 0,
				incoicenumbers: '',
				invoucedcdetail: '',
				electronlist: [],
				elecontext: '',
			};
		},
		beforeCreate() {
			this.$store.commit("commonInfo", "");
		},
		created() {
			let data = {
				keys: ["invoice.bill.type.list"]
			}
			this.common.getCommonInfo(this, data);
		},
		computed: {
			common_info: {
				get() {
					let stateuser = {
						'invoice.bill.type.list':[],
					}
					let val = this.$store.state.common_info || stateuser;
					return val
				},
				set() { }
			}
		},
		mounted() {
			this.invoucedcdetailapi();
		},
		methods: {
			imgbrand(val){
				if(this.is_multiple){
					let imgval = JSON.parse(JSON.stringify(this.invoiceimglist));
					imgval=imgval.concat(val);
					this.invoiceimglist = imgval;
				}
				this.$forceUpdate();
			},
			photoVisible(num) {
				this.is_multiple = num;
				this.$refs.imglist.photoVisible = true;
			},
			delectimg(index){
				this.invoiceimglist.splice(index, 1);
			},
			addelectron(){
				if(!this.elecontext){
					this.$message({
						showClose: true,
						message: '请填写电子票地址',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				const urlregex = /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/.test(this.elecontext);
				if(!urlregex){
					this.$message({
						showClose: true,
						message: '请填写有效的url链接',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				if(this.electronlist.length>=6){
					this.$message({
						showClose: true,
						message: '最多添加6条',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				this.electronlist.push({"file_url":this.elecontext})
			},
			delectelectron(index){
				this.electronlist.splice(index, 1);
			},
			invoiceupimg(){
				let file_path = [];
				if(this.invoiceradio == 0){
					if(!this.invoiceimglist.length){
						this.$message({
							showClose: true,
							message: '请上传纸质发票',
							type: 'warning',
							offset: 200,
							duration: 1000
						});
						return false;
					}
					for(let i=0;i<this.invoiceimglist.length;i++){
						file_path.push(this.invoiceimglist[i].file_url)
					}
				}else{
					if(!this.electronlist.length){
						this.$message({
							showClose: true,
							message: '请添加电子发票',
							type: 'warning',
							offset: 200,
							duration: 1000
						});
						return false;
					}
					for(let i=0;i<this.electronlist.length;i++){
						file_path.push(this.electronlist[i].file_url)
					}
				}
				let data = {
					file_path:file_path,
					bill_type: this.invoiceradio,
				};
				// console.log(data)
				this.$put(this.$apis.invoiceupload+this.id,data).then(res => {
					// console.log(res)
					if (res.code == 200) {
						this.$message({
							type: 'success',
							duration: 1500,
							message: '设置成功',
							onClose :()=>{
								this.upinvoicetype = false;
								this.invoiceimglist = [];
								this.electronlist = [];
								this.elecontext = '';
								file_path = [];
								this.invoucedcdetailapi();
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			
			invoucedcdetailapi(){
				this.$get(this.$apis.invoicedetail + this.id).then(res => {
					if (res.code == 200) {
						// console.log(res)
						this.invoucedcdetail = res.data;
						this.invoiceradio = res.data.bill_type;
						if(res.data.bill_type == 1){
							this.electronlist = res.data.invoice_path
						}else{
							this.invoiceimglist = res.data.invoice_path;
							// console.log(this.invoiceimglist)
						}
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			
			xiugai(innumber,id){
				if(!innumber){
					this.$message({
						showClose: true,
						message: '请填写发票号',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				let data = {
					invoice_no: innumber
				};
				// console.log(data)
				this.$put(this.$apis.invoicenumber+id,data).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							duration: 1500,
							message: '设置成功',
							onClose :()=>{
								this.invoucedcdetailapi();
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			
			copy(data) {
				let url = data;
				let oInput = document.createElement('input');
				oInput.value = url;
				document.body.appendChild(oInput);
				oInput.select(); // 选择对象;
				document.execCommand("Copy"); // 执行浏览器复制命令
				this.$message({
					message: '复制成功',
					type: 'success'
				});
				oInput.remove()
			},
			headback() {
				window.history.go(-1)
			},
		}
	};
</script>
<style scoped>
	@import url("css/invoicedetail.css");
	.upinvoiceinput input{float: left;border: 1px solid rgba(0,0,0,0.1);padding: 0 12px;border-radius: 4px 0 0 4px;font-size: 14px;
	color: #333;outline: none;box-sizing: border-box;height: 38px;width: 75%;transition: .3s;}
	.upinvoiceinput input:hover{border-color: var(--fontColor,#fb6638);}
	.upinvoiceinput input:focus{border-color: var(--fontColor,#fb6638);}
	.upinvlist{width: 100%;margin-top: 10px;}
	.upinvlistcon{width: 100%;position: relative;}
	.upinvlist input{border: 1px solid rgba(0,0,0,0.1);padding: 0 12px;border-radius: 4px;display: block;padding-right: 40px;
	font-size: 14px;color: #333;outline: none;box-sizing: border-box;height: 38px;width: 100%;margin-bottom: 10px;}
	.upinvlist input:last-child{margin-bottom: 0;}
	.upinvlist strong{position: absolute;top: 0;right: 0px;font-size: 14px;color: #333;width: 30px;height: 38px;text-align: center;
	line-height: 38px;cursor: pointer;}
	.deliveimgs{float: left;}
	.deliveimgs p{float: left;width: 80px;height: 80px;margin-right: 6px;margin-bottom: 6px;position: relative;overflow: hidden;}
	.deliveimgs p img{display: block;width: 100%;height: 100%;object-fit: cover;}
	.deliveimgs p span{position: absolute;bottom: -40px;left: 0;width: 100%;font-size: 12px;color: #fff;background: rgba(0,0,0,0.5);
	line-height: 20px;text-align: center;cursor: pointer;transition: all 0.3s;}
	.deliveimgs p:hover span{bottom: 0;}
	.addorderbut input{float: left;width: 75%;border-radius: 4px 0 0 4px;}
	.addorderbut button{float: left;width: 25%;border-radius: 0 4px 4px 0;color: #fff;
	background: var(--fontColor,#fb6638);font-size: 14px;height: 38px;border: 0;}
</style>